<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"
    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @detail="detail"

    />

  </div>
</template>
<script>
// import {timer} from '@/assets/js/comment'
import {projectList} from "@/api/project_management/project_list";
export default {
  components: {},
  props: [],
  data() {
    return {
      //title 数据源
      titleProp: {
        name: '报销附件',//标题名称
        butOperate: []
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '项目编号',
          field: 'project_sn',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目名称',
          field: 'project_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目类型',
          field: 'project_type',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'type_name'
        }, {
          title: '创建时间',
          field: 'created_at',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '查看报销附件',
            type: 'primary',
            method: 'detail',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      //表格数据
      tableData: [],

    };
  },
  created() {
    this.initLoad();
  },
  mounted() {
  },
  methods: {
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        is_show_all: 1,
        page: this.tableProp.page.current_page,
        // keywords: this.tabsProp.keywords,
        // this.tabsProp.active //tabs 当前的值
        // name: this.tabsProp.heighKewWordField.name
      }
      projectList(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;

        for (let i = 0; i < res.data.length; i++){
          //状态:-1=作废，0=待成单，1=成单，2=不成单，3=已指派（进行中，待完工），4=完工（待验收），5=验收（已完成）  ，6=技术结题，7=市场结题，8=项目完结
          switch (res.data[i].status) {
            case 0:
              res.data[i].status = '待成单';
              break;
            case -1:
              res.data[i].status = '作废';
              break;
            case 1:
              res.data[i].status = '成单';
              break;
            case 2:
              res.data[i].status = '不成单';
              break;
            case 3:
              res.data[i].status = '已指派';
              break;
            case 4:
              res.data[i].status = '完工';
              break;
            case 5:
              res.data[i].status = '验收';
              break;
            case 6:
              res.data[i].status = '技术结题';
              break;
            case 7:
              res.data[i].status = '市场结题';
              break;
            case 8:
              res.data[i].status = '项目完结';
              break;
          }
          //审批状态：0=待审批，1=审核通过，2=审核不通过
          switch (res.data[i].audit_status) {
            case 0:
              res.data[i].audit_status = '待审批';
              break;
            case 1:
              res.data[i].audit_status = '审核通过';
              break;
            case 2:
              res.data[i].audit_status = '审核不通过';
              break;
          }
          switch (res.data[i].can_re) {
            case 20000:
              res.data[i].controlName = 'control';
              break;
            case 0:
              res.data[i].controlName = '0';
              break;
          }
          res.data[i].statusName = res.data[i].status + res.data[i].audit_status
          res.data[i].created_at = res.data[i].project_market.created_at
        }
        this.tableData = res.data
        console.log(res.data)
      })
    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },

    //table 查看文档 按钮点击
    detail(row, index) {
      console.log(index)
      this.$router.push({path:'project_reimburse_file/detail',query:{id:row.id}})
    },
  },
};
</script>
<style lang="scss" scoped></style>
