<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"
        @addModule="addModule"
    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @detail="detail"
        @deleteMo="deleteMo"
    />
<!--    添加模块库-->
    <el-dialog
        :append-to-body="true"
        :title="addData.title"
        :visible.sync="addData.isDialog"
        :top="addData.top"
        :width="addData.width"
    >
      <div class="common-add-dialog">
        <ul class="cdul">
          <li>
            <span :style="{'width':addData.labelWidth}">贡献人</span>
            <el-select v-model="addData.addDialogShowData.user_id" :disabled="depName== '总经办'?false:true">
              <el-option
                  v-for="(itemOption,keyOption) in userName"
                  :key="keyOption"
                  :label="itemOption.name"
                  :value="itemOption.id">
              </el-option>
            </el-select>
          </li>
          <li>
            <span :style="{'width':addData.labelWidth}">功能模块名称</span>
            <el-input v-model="addData.addDialogShowData.name"></el-input>
          </li>
          <li>
            <span :style="{'width':addData.labelWidth}">功能模块文本</span>
            <el-input type="textarea" v-model="addData.addDialogShowData.content"></el-input>
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addData.isDialog=false">取 消</el-button>
        <el-button type="primary" @click="subAdd">确 定</el-button>
      </div>
    </el-dialog>
<!--    查看模块库-->
    <el-dialog
        :append-to-body="true"
        :title="detailData.title"
        :visible.sync="detailData.isDialog"
        :top="detailData.top"
        :width="detailData.width"
    >
      <div class="common-add-dialog">
        {{this.detailData.content}}
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="detailData.isDialog=false">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
// import {timer} from '@/assets/js/comment'

import {addModule, delModule, detailModule, getList} from "@/api/document_management/functional_module";
import {getshenPr} from "@/api/basic_management/template_management";

export default {
  components: {},
  props: [],
  data() {
    return {
      userName: [],
      addData: {
        isDialog: false,//dialog是否显示
        title: '添加功能模块',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '500px', // dialog 长度，可以是百分比
        labelWidth: '100px', // label 长度
        //dialog 显示数据
        addDialogShowData: {
          user_id:'',
          name: '',
          content: '',
        },
      },
      detailData: {
        isDialog: false,//dialog是否显示
        title: '',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '500px', // dialog 长度，可以是百分比
        labelWidth: '100px', // label 长度
        //dialog 显示数据
        content: '',
      },
      //title 数据源
      titleProp: {
        name: '功能模块库',//标题名称
        butOperate: [{
          name: '添加功能模块',
          type: 'primary',
          method: 'addModule',
          id: 2000,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '功能模块名称',
          field: 'name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '贡献人',
          field: 'user_info',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name'
        }, {
          title: '贡献时间',
          field: 'created_at',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '查看',
            type: 'primary',
            method: 'detail',
            status: '20000',
            id: 20000,
          },{
            name: '删除',
            type: 'danger',
            method: 'deleteMo',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      //表格数据
      tableData: [],
      depName: '',
    };
  },
  created() {
    this.initLoad();
    this.depName = localStorage.getItem('departmentName')
  },
  mounted() {
  },
  methods: {
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        // keywords: this.tabsProp.keywords,
        // this.tabsProp.active //tabs 当前的值
        // name: this.tabsProp.heighKewWordField.name
      }
      getList(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;

        this.tableData = res.data
        console.log(res)

      })
    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },

  //  添加模块库
    addModule(){
      getshenPr().then((result)=>{
        console.log(result)
        this.userName = result.data
      })
      this.addData.addDialogShowData.user_id = parseInt(localStorage.getItem('userId'))
      this.addData.isDialog = true

    },
    subAdd(){
      console.log(this.addData.addDialogShowData)
      addModule(this.addData.addDialogShowData).then(res=>{
        console.log(res)
        this.addData.isDialog = false
        this.$message.success("添加成功")
        this.initLoad()
      })
    },

  //  删除模块库
    deleteMo(row){
      console.log(row)
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delModule({id: row.id}).then(res=>{
          console.log(res)
          this.initLoad()
        })
      })

    },

  //  模块库详情
    detail(row){
      console.log(row)
      console.log(row.id)
      let data = {
        id: row.id
      }
      detailModule(data).then(res=>{
        console.log(res)
        this.detailData.content = res.data.content
        this.detailData.title = row.name
        this.detailData.isDialog = true
      })
    },
  },
};
</script>
<style lang="scss" scoped>

.cdul{
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 120px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }


  }
}
.common-add-dialog {
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 120px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }

  }
}
</style>
