<template>
  <div>
    <!-- title 组件 -->
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>项目报销附件</span>
      </div>
    </div>
    <common-document :uploadFileData="doc" :type = 'type'/>
  </div>
</template>
<script>
// import {getGsDoc} from "@/api/document_management/contract_template";
import {projectReimburseFile} from "@/api/project_management/project_list";
export default {
  props: [],
  components: {},
  data() {
    return {
      type: '',
      doc:[],
      uploadFileField: {remarks: 1}, //上传添加的字段
      uploadFileUrl: process.env.VUE_APP_URL + '/api/common/uploadFile',
    }
  },
  created() {
    this.initLoad()
  },
  mounted() {

  },
  methods: {
    initLoad() {
      // this.type = localStorage.getItem("departmentName")
      // getGsDoc().then(res=>{
      //   for (let i = 0; i<res.data.data.length; i++){
      //     res.data.data[i].url = res.data.data[i].attachment_json
      //   }
      //   this.doc = res.data.data
      // })
      projectReimburseFile({project_id:this.$route.query.id}).then(res=>{
        let data = []
        for (let i = 0; i<res.data.length; i++){
          data.push(JSON.parse(res.data[i].attachment_json))
          // let item = []
          //
          // for (let j = 0; j < JSON.parse(res.data[i].attachment_json).length; j++) {
          //   item[j].url = JSON.parse(res.data[i].attachment_json)[j].url
          //   item[j].file_name = JSON.parse(res.data[i].attachment_json)[j].reason
          // }
          // console.log(item);

          // data[i].url = JSON.parse(res.data[i].attachment_json)
          // data[i].file_name = res.data[i].reason
        }
        console.log(this.doc);
        this.doc=[];
        data.forEach(item=>{
          item.forEach(i=>{
            this.doc.push(i)
          })
        })
        console.log(this.doc);

      })
    },
    //附件 上传失败的钩子
    uploadFileError() {
      this.$message.error('附件上传失败');
    },
    //附件 上传成功钩子
    uploadFileSuccess(file) {
      console.log(file)
      // let data = {
      //   file_name: file.data.file_name,
      //   attachment_json: file.data.url
      // }
      // addDoc(data).then(res=>{
      //   console.log(res)
      //   this.$message.success('上传成功');
      //   this.initLoad()
      // })
    },
  },
}
</script>

<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
</style>