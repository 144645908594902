import http from '../http';

export function getList (params) {
    return http({
        url: "/api/ltd/modeStorePage",
        method: "get",
        params:params
    })
}

export function addModule (data) {
    return http({
        url: "/api/ltd/modeStore",
        method: "put",
        data:data
    })
}

export function delModule (data) {
    return http({
        url: "/api/ltd/modeStore",
        method: "delete",
        data:data
    })
}

export function detailModule (params) {
    return http({
        url: "/api/ltd/modeStore",
        method: "get",
        params:params
    })
}